<template>
  <DashboardCard :title="$t('Dashboard.Radius.Title')" icon="IconRadius" url="/keurmerk">
    <div class="flex flex-col gap-2">
      <router-link
        :to="{
          name: 'QualityAssessment',
          params: {
            homeID
          }
        }"
      >
        <DashboardButton v-if="$permissions.has('home-viewqualityassessment')">
          <span class="flex-grow">{{ $t('Dashboard.Widget.Radius.Action.QualityAssessment') }}</span>
          <div class="flex items-center" v-if="progressReportRadius">
            <div class="w-10">
              <ProgressBar :percentage="progressReportRadius.progress_percentage"></ProgressBar>
            </div>
            <span class="text-theme-50">
              <span class="text-progress-fill ml-2"> {{ progressReportRadius.progress_percentage }}% </span>
            </span>
          </div>
        </DashboardButton>
      </router-link>

      <DashboardButton
        v-if="$permissions.has('home-viewlivingenvironmentassessment')"
        @click.native="showLivingEnvironmentAssessment"
      >
        {{ $t('Dashboard.Widget.Radius.Action.EnvironmentAssessment') }}
      </DashboardButton>

      <DashboardButton
        v-if="$permissions.has('home-viewcollaborationassessment')"
        @click.native="showCollaborationAssessment"
      >
        <span class="flex-grow truncate">{{ $t('Dashboard.Widget.Radius.Action.CollaborationAssessment') }}</span>
      </DashboardButton>
    </div>
  </DashboardCard>
</template>

<script>
import DashboardButton from '@/components/dashboard/DashboardButton.vue';
import DashboardCard from '@/components/dashboard/DashboardCard.vue';

import ProgressBar from '@/components/ProgressBar';
import { GraphQLQuery } from '@/graphql';

export default {
  components: {
    DashboardButton,
    DashboardCard,
    ProgressBar
  },

  apollo: {
    chapters: {
      query: GraphQLQuery.RadiusQualityChapters
    },

    progressReportRadius: {
      query: GraphQLQuery.ProgressReportRadius,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUser;
      }
    },
    configuration: {
      query: GraphQLQuery.Configuration
    }
  },

  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    }
  },

  methods: {
    showLivingEnvironmentAssessment() {
      const chapter =
        this.chapters?.find(ch => ch.name === this.$t('Dashboard.Widget.Radius.Action.EnvironmentAssessment')) ?? null;

      if (chapter && (!chapter.action || chapter.action === 'show_paragraphs')) {
        this.$router.push({
          name: 'QualityAssessmentDetail',
          params: {
            chapterID: chapter.id,
            homeID: this.homeID
          }
        });
        return;
      }

      if (
        this.configuration &&
        this.configuration.environment_research &&
        this.configuration.environment_research.environment_research_enabled
      ) {
        this.$router.push({
          name: 'LivingEnvironmentAssessment',
          params: {
            homeID: this.homeID
          }
        });
        return;
      }

      this.displayComingSoonModal(
        this.$t('Radius.EnvironmentAssessment'),
        `1x per jaar word je gevraagd om deel te nemen aan het ${this.$t(
          'Radius.EnvironmentAssessment'
        )}. Dit wordt uitgevoerd in samenwerking met de Hogeschool Leiden en werkt aan de hand van een digitale vragenlijst. De resultaten krijg je in de vorm van een rapport toegestuurd en kan je uploaden in het portal.`
      );
    },

    showCollaborationAssessment() {
      const chapter =
        this.chapters?.find(ch => ch.name === this.$t('Dashboard.Widget.Radius.Action.CollaborationAssessment')) ??
        null;

      if (chapter && (!chapter.action || chapter.action === 'show_paragraphs')) {
        this.$router.push({
          name: 'QualityAssessmentDetail',
          params: {
            chapterID: chapter.id,
            homeID: this.homeID
          }
        });
        return;
      }

      this.displayComingSoonModal(
        this.$t('Radius.CollaborationAssessment'),
        `Voor 2023 wordt een vernieuwde vorm van het ${this.$t(
          'Radius.CollaborationAssessment'
        )} gebruikt. Je leest meer informatie bij het onderdeel ${this.$t(
          'Radius.CollaborationAssessment'
        )} in je startkwalificatie (punt 6).`
      );

      this.$router.push({
        name: 'CollaborationAssessment',
        params: {
          homeID: this.homeID
        }
      });
    },

    displayComingSoonModal(title, body) {
      this.$modal.show('ComingSoon', { title, body });
    }
  }
};
</script>
