<template>
  <main class="bg-gray-100 py-16">
    <UploadModal
      ref="uploadModal"
      :libraryEnabled="true"
      @completed="uploadCompleted"
      :uploadLibraryType="uploadLibraryType"
      :homeID="homeID"
    />
    <SelectDocumentsModal ref="selectDocumentsModal" @completed="linkedDocumentsSelected" />
    <div v-if="$apollo.loading" class="flex flex-col items-center justify-center w-full h-full pt-8">
      <img class="w-8 h-8 mb-4" src="@/assets/loading-dark.gif" alt="Loading" />
      <span class="text-150 font-semibold text-darkblue">
        {{ $t('General.Loading') }}
      </span>
    </div>
    <div class="max-w-7xl mx-auto pb-12 px-4 md:px-6 lg:px-8" v-if="activeParagraph">
      <RadiusBreadcrumbs :title="`${activeParagraph.chapter.sort_number}. ${activeParagraph.chapter.name}`" />

      <div class="flex flex-col md:flex-row">
        <ParagraphChecklist
          v-if="paragraphs"
          class="mr-0 md:mr-16 mt-0 md:mt-12 mb-8 md:mb-0"
          :paragraphs="paragraphs"
          :activeParagraph="activeParagraphIndex"
          :userDataParagraphs="userDataParagraphs"
          @changeActiveParagraph="changeActiveParagraph"
        />
        <div
          class="bg-white w-full px-8 pt-12 pb-20 border border-b-4 border-gray-200 rounded-md text-theme-200"
          :class="{
            'animation-page-scale-in animation-once animation-fill-backwards animation-300': !animatePageOut,
            'animation-page-scale-out animation-once animation-fill-backwards animation-100': animatePageOut
          }"
        >
          <span class="block text-theme-300 mb-3">
            {{ activeParagraph.chapter.sort_number }}.{{ activeParagraph.sort_number }} {{ activeParagraph.name }}
          </span>

          <ReadMoreSpan
            class="block mb-12 text-100"
            :text="activeParagraph.description"
            :maxLength="400"
            :markdown="true"
          />

          <div
            v-if="hasUploadPermissions"
            class="text-theme-250 font-bold text-darkblue mb-6 flex flex-col sm:flex-row justify-between items-center"
          >
            <span class="text-200 font-semibold text-darkblue">{{ $t('Radius.QualityAssessment.Documents') }}</span>
            <div
              class="
                flex flex-row
                cursor-pointer
                text-lightblue-link text-100
                font-semibold
                mt-4
                sm:mt-0
                focus:outline-none
                focus:underline
                transition
                ease-in-out
                duration-150
              "
              @click="showUploadDialog"
            >
              <IconAdd class="mr-1" />
              <span>{{ $t('Radius.QualityAssessment.Action.AddDocument') }}</span>
            </div>
          </div>

          <div
            v-if="hasUploadPermissions && (!documents || documents.length == 0)"
            @click="showUploadDialog"
            class="
              w-full
              border-2 border-dashed border-gray-300
              bg-gray-100
              flex flex-col
              sm:flex-row
              text-center
              items-center
              px-6
              py-7
              rounded-md
              cursor-pointer
              text-100
            "
          >
            <IconExclamationMark class="w-5 h-5 mr-4 flex-shrink-0" />
            {{ $t('Radius.QualityAssessment.NoDocumentsFound') }}
            <button class="text-lightblue-link ml-1 font-semibold focus:outline-none lowercase">
              {{ $t('Radius.QualityAssessment.Action.AddDocument') }}
            </button>
          </div>
          <div
            v-if="isFileUploadParagraph && !hasUploadPermissions && (!documents || documents.length === 0)"
            class="text-theme-100 mb-6 flex flex-col sm:flex-row justify-start items-center"
          >
            <IconExclamationMark class="w-5 h-5 mr-4 flex-shrink-0" />
            <span>{{ $t('ProgressReport.NoDocumentsFoundAdminManaged') }}</span>
          </div>

          <div
            v-if="isQuarterlyReport || isYearlyReport"
            class="flex flex-col gap-y-4"
            :class="{
              'mb-8': documents && documents.length > 0
            }"
          >
            <button
              v-if="$permissions.has('yearly-quarterly-report-upsert')"
              class="flex text-left text-lightblue-link items-center focus:outline-none cursor-pointer hover:underline"
              @click="createYearlyMonthlyReport(isYearlyReport)"
            >
              <IconAdd class="flex-shrink-0" />
              <div class="flex flex-col flex-1 ml-3">
                <span v-if="!yearlyQuarterlyReport" class="text-50 font-semibold flex-1 break-all">{{
                  isYearlyReport ? 'Maak jaarplan' : 'Maak kwartaalplan'
                }}</span>
                <span v-else class="text-50 font-semibold flex-1 break-all"
                  >{{ isYearlyReport ? 'Verder met jaarplan' : 'Verder met kwartaalplan' }} ({{
                    yearlyQuarterlyReport?.title
                  }})</span
                >
              </div>
            </button>
            <div v-else>
              <span class="text-50 font-semibold">{{ $t('Radius.QualityAssessment.NoPermissionToCreateReport') }}</span>
            </div>
          </div>

          <div v-if="documents && documents.length > 0">
            <div
              class="
                w-full
                px-6
                py-4
                flex
                items-center
                justify-between
                bg-white
                text-darkblue
                rounded-md
                border border-gray-200
                mb-1
              "
              v-for="(doc, index) in visibleDocumentsLengthCapEnabled
                ? documents.slice(0, visibleDocumentsLengthCap)
                : documents"
              :key="index"
              :class="{
                [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
              }"
            >
              <button
                class="flex text-left items-center focus:outline-none cursor-pointer flex-1"
                @click="$helper.showDocumentDetailsModal(doc)"
              >
                <IconFile class="flex-shrink-0" :document="doc" />
                <div class="min-w-2xl ml-8">
                  <span class="block text-theme-150 font-semibold">{{ $filters.documentname(doc) }}</span>
                  <span class="block text-theme-100">
                    <span>Geupload op {{ doc.created_at | date }}</span>
                    <span
                      v-if="doc.expiration_date"
                      :class="{
                        'text-red-500 font-semibold': $filters.monthsremaining(doc.expiration_date) <= 0,
                        'text-yellow-400 font-semibold':
                          $filters.monthsremaining(doc.expiration_date) > 0 &&
                          $filters.monthsremaining(doc.expiration_date) <= 2
                      }"
                    >
                      - Verloopt op {{ doc.expiration_date | date }}</span
                    >
                  </span>
                </div>
              </button>
              <div class="flex text-lightblue-hover">
                <button
                  v-if="
                    (hasUploadPermissions || doc.libraryType === 'private') && !isYearlyReport && !isQuarterlyReport
                  "
                  class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
                  @click="replaceDocument(doc)"
                >
                  <IconReplace class="flex-shrink-0 group-hover:text-darkblue" />
                  <span
                    class="
                      absolute
                      py-1
                      px-2
                      text-50
                      bg-darkblue
                      text-white
                      rounded-md
                      transform
                      translate-y-7
                      opacity-0
                      invisible
                      group-hover:visible group-hover:opacity-100
                      transition
                      ease-in-out
                      duration-150
                    "
                  >
                    {{ $t('Radius.QualityAssessment.Action.ReplaceDocument') }}
                  </span>
                </button>
                <button
                  class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
                  @click="$helper.downloadDocument(doc)"
                >
                  <IconDownload class="flex-shrink-0 group-hover:text-darkblue" />
                  <span
                    class="
                      absolute
                      py-1
                      px-2
                      text-50
                      bg-darkblue
                      text-white
                      rounded-md
                      transform
                      translate-y-7
                      opacity-0
                      invisible
                      group-hover:visible group-hover:opacity-100
                      transition
                      ease-in-out
                      duration-150
                    "
                  >
                    {{ $t('Radius.QualityAssessment.Action.DownloadDocument') }}
                  </span>
                </button>
                {{ hasUploadPermissions }}
                {{ doc.libraryType === 'private' }}
                {{ !isYearlyReport }}
                {{ !isQuarterlyReport }}

                <button
                  v-if="
                    (hasUploadPermissions || doc.libraryType === 'private') && !isYearlyReport && !isQuarterlyReport
                  "
                  class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
                  @click="
                    $modal.confirm(() => {
                      deleteDocument(doc);
                    }, 'ConfirmDeleteDocument')
                  "
                >
                  <IconTrash class="flex-shrink-0 group-hover:text-darkblue" />
                  <span
                    class="
                      absolute
                      py-1
                      px-2
                      text-50
                      bg-darkblue
                      text-white
                      rounded-md
                      transform
                      translate-y-7
                      opacity-0
                      invisible
                      group-hover:visible group-hover:opacity-100
                      transition
                      ease-in-out
                      duration-150
                    "
                  >
                    {{ $t('Radius.QualityAssessment.Action.RemoveDocument') }}
                  </span>
                </button>
              </div>
            </div>
            <div class="text-50 font-semibold leading-5 ml-6 mt-3">
              <span
                class="text-lightblue-link focus:outline-none focus:underline transition ease-in-out duration-150"
                v-if="visibleDocumentsLengthCapEnabled && documents && documents.length > visibleDocumentsLengthCap"
                @click="visibleDocumentsLengthCapEnabled = false"
              >
                {{
                  $t('Radius.QualityAssessment.ViewMoreFiles') | format(documents.length - visibleDocumentsLengthCap)
                }}
              </span>
            </div>
          </div>
          <div class="flex flex-col-reverse xl:flex-row mt-8 xl:mt-20">
            <div class="text-theme-200 w-full">
              <span class="block text-200 font-semibold text-darkblue mb-6">{{
                $t('Radius.QualityAssessment.Comments')
              }}</span>
              <Comments
                v-if="$permissions.has('comment-create')"
                class="w-full xl:min-w-radius-comments"
                :placeholder="$t('Radius.QualityAssessment.Comments.Placeholder')"
                :paragraph="activeParagraph"
                :activeUserDataParagraph="activeUserDataParagraph"
                @paragraphUserDataCreated="paragraphUserDataCreated"
              />
              <span class="w-full text-100" v-else>
                {{ $t('Radius.QualityAssessment.Comments.InsufficientPermissions') }}
              </span>
            </div>
            <div class="ml-0 xl:ml-10 mb-8 xl:mb-0 sm:min-w-radius-linked-documents flex-1 text-theme-250">
              <div class="flex items-center gap-x-2 mb-6">
                <span
                  class="block text-200 font-semibold text-darkblue"
                  v-if="activeParagraph.linked_documents.length > 0 || $permissions.hasAdminRole()"
                  >{{ $t('Radius.QualityAssessment.LinkedDocuments') }}</span
                >

                <span
                  v-if="$permissions.hasAdminRole()"
                  class="text-lightblue-link text-100 group underline cursor-pointer"
                  @click="changeLinkedDocuments"
                  >{{ $t('Radius.QualityAssessment.Action.AddDocument') }}
                </span>
              </div>
              <div
                v-for="(doc, index) in visibleLinkedDocumentsLengthCapEnabled
                  ? activeParagraph.linked_documents.slice(0, visibleLinkedDocumentsLengthCap)
                  : activeParagraph.linked_documents"
                :key="index"
                class="flex items-center gap-x-2 flex-shrink-0 
                  mb-1"
              >
                <button
                  class="
                  flex
                  w-full
                  items-center
                  border border-lightblue
                  rounded-md
                  px-4
                  py-3
                  hover:border-lightblue-hover
                  focus:outline-none
                  transition
                  ease-in-out
                  duration-150
                  text-theme-200
                "
                  :class="{
                    [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index +
                      5}`]: visibleLinkedDocumentsLengthCapEnabled
                  }"
                  @click="$helper.downloadDocument(doc)"
                >
                  <IconFile :document="doc" class="flex-shrink-0" />
                  <span class="block font-medium ml-4 text-100 text-left break-all">{{
                    $filters.documentname(doc)
                  }}</span>
                </button>

                <div
                  v-if="$permissions.hasAdminRole()"
                  class="p-4 rounded-lg cursor-pointer hover:bg-gray-300"
                  @click="deleteLinkedDocument(doc)"
                >
                  <IconTrash class="flex-shrink-0 group-hover:text-darkblue" />
                </div>
              </div>
              <span
                class="
                  text-lightblue-link text-100
                  focus:outline-none
                  focus:underline
                  transition
                  ease-in-out
                  duration-150
                  cursor-pointer
                "
                v-if="
                  visibleLinkedDocumentsLengthCapEnabled &&
                    activeParagraph.linked_documents &&
                    activeParagraph.linked_documents.length > visibleLinkedDocumentsLengthCap
                "
                @click="visibleLinkedDocumentsLengthCapEnabled = false"
              >
                {{
                  $t('Radius.QualityAssessment.ViewMoreFiles')
                    | format(activeParagraph.linked_documents.length - visibleLinkedDocumentsLengthCap)
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import UploadModal from '@/components/modals/UploadModal';
import SelectDocumentsModal from '@/components/modals/SelectDocumentsModal';
import RadiusBreadcrumbs from '@/components/radius/RadiusBreadcrumbs';
import Comments from '@/components/Comments';
import ReadMoreSpan from '@/components/ReadMoreSpan';
import ParagraphChecklist from '@/components/ParagraphChecklist';

export default {
  name: 'QualityAssessmentDetail',
  components: {
    UploadModal,
    SelectDocumentsModal,
    RadiusBreadcrumbs,
    Comments,
    ReadMoreSpan,
    ParagraphChecklist
  },
  apollo: {
    userDataParagraphs: {
      query: GraphQLQuery.RadiusQualityParagraphsUserData,
      variables() {
        return {
          homeID: this.homeID,
          chapterID: this.$route.params.chapterID
        };
      },
      update(data) {
        return data.paragraphHomes;
      },
      result() {
        this.configureActiveParagraphIndex();
      }
    },
    paragraphs: {
      query: GraphQLQuery.RadiusQualityParagraphs,
      variables() {
        return {
          chapterID: this.$route.params.chapterID
        };
      },
      result() {
        this.configureActiveParagraphIndex();
      }
    },
    yearlyQuarterlyReport: {
      query: GraphQLQuery.FindYearlyQuarterlyReport,
      fetchPolicy: 'network-only',
      variables() {
        return {
          identifier: `hm-${this.isYearlyReport ? 'yearly' : 'quarterly'}-unfinished`
        };
      },
      skip() {
        return !this.isYearlyReport && !this.isQuarterlyReport;
      },
      update(data) {
        return data.report;
      }
    }
  },
  data() {
    return {
      activeParagraphIndex: -1,
      visibleDocumentsLengthCapEnabled: true,
      visibleDocumentsLengthCap: 3,
      visibleLinkedDocumentsLengthCapEnabled: true,
      visibleLinkedDocumentsLengthCap: 5,
      animatePageOut: false,
      replacementDocument: null
    };
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    activeParagraph() {
      if (!this.paragraphs || this.activeParagraphIndex < 0 || this.activeParagraphIndex >= this.paragraphs.length) {
        return null;
      }

      return this.paragraphs[this.activeParagraphIndex];
    },

    activeUserDataParagraph() {
      if (!this.activeParagraph || !this.userDataParagraphs || this.userDataParagraphs.length === 0) {
        return null;
      }

      const currentParagraph = this.userDataParagraphs.find(userDataParagraph => {
        return userDataParagraph.paragraph.id === this.activeParagraph.id;
      });

      return currentParagraph;
    },

    isYearlyReport() {
      return this.activeParagraph?.block_type?.toLowerCase() === 'jaarplan';
    },

    isQuarterlyReport() {
      return this.activeParagraph?.block_type?.toLowerCase() === 'kwartaalplan';
    },

    documents() {
      if (!this.userDataParagraphs || this.userDataParagraphs.length === 0 || !this.activeParagraph) {
        return null;
      }

      const result = this.userDataParagraphs.find(userDataParagraph => {
        return userDataParagraph.paragraph.id === this.activeParagraph.id;
      });

      if (!result) {
        return [];
      }

      return [...result.documents].sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },

    isFileUploadParagraph() {
      if (!this.activeParagraph) {
        return false;
      }

      return this.activeParagraph.block_type && this.activeParagraph.block_type.toLowerCase().startsWith('file_upload');
    },

    hasUploadPermissions() {
      if (!this.activeParagraph || !this.isFileUploadParagraph) {
        return false;
      }

      if (
        this.activeParagraph.block_type &&
        this.activeParagraph.block_type.toLowerCase() === 'file_upload_admin_only'
      ) {
        return this.$permissions.hasAdminRole();
      }

      if (!this.$permissions.has('paragraph-home-create') || !this.$permissions.has('paragraph-home-update')) {
        return false;
      }

      return true;
    },

    uploadLibraryType() {
      if (
        this.$permissions.hasAdminRole() &&
        this.activeParagraph &&
        this.activeParagraph.block_type &&
        this.activeParagraph.block_type.toLowerCase() === 'file_upload_admin_only'
      ) {
        return 'private_admin';
      }

      return 'private';
    }
  },
  methods: {
    changeLinkedDocuments() {
      this.$refs.selectDocumentsModal.show();
    },

    deleteLinkedDocument(doc) {
      this.activeParagraph.linked_documents = this.activeParagraph.linked_documents.filter(item => {
        return item.id !== doc.id;
      });

      this.updateLinkedDocuments();
    },

    async linkedDocumentsSelected(addedDocuments) {
      this.activeParagraph.linked_documents = this.activeParagraph.linked_documents.concat(addedDocuments);
      this.updateLinkedDocuments();
    },

    changeActiveParagraph(index) {
      this.animatePageOut = true;
      setTimeout(() => {
        this.animatePageOut = false;
        this.activeParagraphIndex = index;
        this.$apollo.queries.userDataParagraphs.refresh();
      }, 100);
    },

    configureActiveParagraphIndex() {
      if (this.$apollo.loading || !this.paragraphs || this.activeParagraphIndex >= 0) {
        return;
      }

      const unfinishedParagraphs = this.paragraphs.filter(paragraph => {
        const result = (this.userDataParagraphs || []).find(userDataParagraph => {
          return (
            paragraph.id === userDataParagraph.paragraph.id &&
            userDataParagraph.status &&
            userDataParagraph.status !== 'open' &&
            this.$helper.expiredDocumentsCount(userDataParagraph.documents) === 0
          );
        });
        return !result;
      });

      if (unfinishedParagraphs && unfinishedParagraphs.length > 0) {
        this.activeParagraphIndex = this.paragraphs.indexOf(unfinishedParagraphs[0]);
      }

      this.activeParagraphIndex = Math.max(0, Math.min(this.activeParagraphIndex, this.paragraphs.length - 1));
    },

    showUploadDialog() {
      this.replacementDocument = null;
      this.$refs.uploadModal.show();
    },

    async uploadCompleted(addedDocuments) {
      try {
        if (this.replacementDocument) {
          await this.deleteDocument(this.replacementDocument);
        }
      } catch {
        // Do nothing
      }

      try {
        let documentIDs = addedDocuments.map(doc => {
          return Number(doc.id);
        });

        if (this.activeUserDataParagraph) {
          documentIDs = documentIDs.concat(
            this.activeUserDataParagraph.documents.map(doc => {
              return Number(doc.id);
            })
          );
        }

        await this.updateDocumentIDs(documentIDs, this.activeUserDataParagraph);
      } catch {
        // Document error
      }
    },

    replaceDocument(targetDocument) {
      this.showUploadDialog();
      this.replacementDocument = targetDocument;
    },

    async createYearlyMonthlyReport(isYearly) {
      this.$router.push({
        name: 'YearlyQuarterlyReport',
        params: {
          homeID: this.homeID,
          paragraphID: this.activeParagraph.id,
          type: isYearly ? 'year' : 'quarter'
        }
      });
    },

    async deleteDocument(targetDocument) {
      if (!this.activeUserDataParagraph) {
        return;
      }

      const remainingDocumentIDs = this.activeUserDataParagraph.documents
        .filter(doc => {
          return doc.id !== targetDocument.id;
        })
        .map(doc => {
          return doc.id;
        });

      await this.updateDocumentIDs(remainingDocumentIDs, this.activeUserDataParagraph);
    },

    async paragraphUserDataCreated(paragraphUserData) {
      const queryCacheStore = this.$apollo.provider.defaultClient.cache;

      const queryInfo = {
        query: GraphQLQuery.RadiusQualityParagraphsUserData,
        variables: {
          homeID: this.homeID,
          chapterID: this.$route.params.chapterID
        }
      };

      const data = queryCacheStore.readQuery(queryInfo);

      data.paragraphHomes.push(paragraphUserData);

      queryCacheStore.writeQuery({
        ...queryInfo,
        data
      });

      this.$apollo.queries.userDataParagraphs.refresh();
    },

    async updateLinkedDocuments() {
      await this.$apollo.mutate({
        mutation: GraphQLMutation.RadiusQualityUpdateLinkedDocuments,
        variables: {
          paragraphID: this.activeParagraph.id,
          documentIDs: this.activeParagraph.linked_documents.map(doc => {
            return doc.id;
          })
        }
      });
    },

    async updateDocumentIDs(documentIDs, currentParagraph = null) {
      await this.$apollo.mutate({
        mutation: GraphQLMutation.RadiusQualityParagraphDocumentLink,
        variables: {
          homeID: this.homeID,
          paragraphID: this.activeParagraph.id,
          documentIDs
        },
        update: (
          queryCacheStore,
          {
            data: {
              createParagraphHome: { paragraphHome }
            }
          }
        ) => {
          let queryInfo = {
            query: GraphQLQuery.RadiusQualityParagraphsUserData,
            variables: {
              homeID: this.homeID,
              chapterID: this.$route.params.chapterID
            }
          };

          let data = queryCacheStore.readQuery(queryInfo);
          if (currentParagraph) {
            data.paragraphHomes = data.paragraphHomes.filter(item => {
              return item.id !== currentParagraph.id;
            });
          }

          data.paragraphHomes.push(paragraphHome);

          queryCacheStore.writeQuery({
            ...queryInfo,
            data
          });

          this.$apollo.queries.userDataParagraphs.refresh();

          // Update Progress cache
          queryInfo = {
            query: GraphQLQuery.RadiusQualityChaptersProgress,
            variables: {
              homeID: this.homeID
            }
          };

          const progress = {
            id: this.activeParagraph.id,
            status: paragraphHome.status,
            chapter: {
              id: this.activeParagraph.chapter.id
            }
          };

          try {
            data = queryCacheStore.readQuery(queryInfo);
          } catch {
            data = {
              progress: []
            };
          }

          if (this.activeParagraph) {
            data.progress = data.progress.filter(item => {
              return item.id !== this.activeParagraph.id;
            });
          }

          data.progress.push(progress);

          queryCacheStore.writeQuery({
            ...queryInfo,
            data
          });
        }
      });
    }
  }
};
</script>
